(<template>
  <div class="projects-page">
    <!-------- Seller/Interpreter Projects List ------>
    <div v-if="userIsInterpreter"
         class="seller-projects">
      <div class="seller-projects__container">
        <projects-list :header-text="myTranslationProjects"
                       :title="availableAssignments"
                       :tabs="filteredAvailableSubtasksTabs"
                       :search-placeholder="searchText"
                       :projects="availableSubtasks"
                       :progress="availableSubtaskProgress"
                       :current-page="currentAvailableSubtasksPage"
                       :total-pages="availableSubtasksPages"
                       pagination-field="availableSubtasks"
                       :smaller-cards="true"
                       :card-color-pallet="sellersCardColor"
                       @search="searchAvailableSubtasks"
                       @newpage="toNewPage"
                       @prev="toPrevPage"
                       @next="toNextPage" />
        <projects-list :title="acceptedAssignments"
                       :tabs="acceptedSubtasksTabs"
                       :search-placeholder="searchText"
                       :smaller-cards="true"
                       :projects="acceptedSubtasks"
                       :progress="acceptedSubtaskProgress"
                       :current-page="currentAcceptedSubtasksPage"
                       :total-pages="acceptedSubtasksPages"
                       pagination-field="acceptedSubtasks"
                       :card-color-pallet="sellersCardColor"
                       @search="searchAcceptedSubtasks"
                       @newpage="toNewPage"
                       @prev="toPrevPage"
                       @next="toNextPage" />
      </div>
    </div>

    <!------- Buyers/Customers Projects List ------>
    <template v-else>
      <projects-list :header-text="myProjectsText"
                     :button-text="orderTranslation"
                     :search-placeholder="orderReferenceText"
                     :tabs="buyersTabs"
                     :projects="projects"
                     :card-color-pallet="customerCardColor"
                     :progress="progressActive"
                     :show-colleagues-orders="showColleaguesOrders"
                     :current-page="currentProjectsPage"
                     :total-pages="totalProjectsPages"
                     pagination-field="projects"
                     @search="searchProject"
                     @header-action="goToCreateTranslation"
                     @header-tab-changed="setCurrentHeaderTab"
                     @newpage="toNewPage"
                     @prev="toPrevPage"
                     @next="toNextPage" />
    </template>
  </div>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    asyncData({store, route}) {
      let promisesArr = [];
      const statuses = {
        new: {
          status: 'active'
        },
        proactive: {
          status: 'proactive_access'
        },
        active: {
          status: 'active'
        },
        rejected: {
          status: 'rejected'
        },
        finished: {
          status: 'finished'
        },
        cancelled: {
          status: 'cancelled'
        },
        reviewRequested: {
          status: 'review_requested'
        },
        changesRequired: {
          status: 'changes_required'
        },
      };

      // ********** Requests for Translators ********* //
      if (store.getters['UserInfoStore/userIsInterpreter']) {
        const availableParams = {
          status: statuses[route.query.availableStatus]?.status || 'active',
          page: route.query.availablePage || 1,
          items: 20,
        };

        const acceptedParams = {
          status: statuses[route.query.acceptedStatus]?.status || 'active',
          page: route.query.acceptedPage || 1,
          items: 20,
        };

        promisesArr = [...promisesArr, store.dispatch('AllSubtasksStore/fetchAvailableSubtasks', availableParams)];
        promisesArr = [...promisesArr, store.dispatch('AllSubtasksStore/fetchAcceptedSubtasks', acceptedParams)];
      }

      const userId = store.getters['UserInfoStore/userUid'];
      promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getUserProfile', {id: userId})];

      // ********** Requests for Customers ********* //
      if (store.getters['UserInfoStore/userIsNotInterpreter']) {
        const {view, page, scope} = route.query;
        const fetchProjects = (type, page, scope = '') => store.dispatch(`AllProjectsStore/fetch${type}Projects`, {page, items: 10, scope});

        if (view === 'active' && !store.state.AllProjectsStore.activeProjectsRequested) {
          promisesArr = [...promisesArr, fetchProjects('Active', page, scope)];
          store.commit('AllProjectsStore/setActiveProjectsRequested', true);
        } else if (view === 'past' && !store.state.AllProjectsStore.pastProjectsRequested) {
          promisesArr = [...promisesArr, fetchProjects('Past', page, scope)];
          store.commit('AllProjectsStore/setPastProjectsRequested', true);
        }
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'projects-list': () => import('@/components/project_components/list/ProjectsList')
    },
    data() {
      return {
        currentHeaderTab: this.$route.query.scope || 'my_projects',
        currentView: this.$route.query.view || 'active',
        availableListView: this.$route.query.availableStatus || 'new',
        acceptedListView: this.$route.query.acceptedStatus || 'active',
        currentProjectsPage: this.$route.query.page || 1,
        currentAvailableSubtasksPage: this.$route.query.availablePage || 1,
        currentAcceptedSubtasksPage: this.$route.query.acceptedPage || 1
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'enterpriseMemberJobsScope',
        'userIsInterpreter',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('EditProfileStore', ['isProactiveUser']),
      ...mapState('AllProjectsStore', {
        progressActive: (state) => state.progressActive,
      }),
      ...mapState('AllSubtasksStore', {
        acceptedSubtaskProgress: (state) => state.acceptedSubtaskProgress,
        availableSubtaskProgress: (state) => state.availableSubtaskProgress,
      }),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {},
      }),
      ...mapGetters({
        activeProjects: 'AllProjectsStore/activeProjects' || {},
        pastProjects: 'AllProjectsStore/pastProjects' || {},
        activeProjectsPages: 'AllProjectsStore/activeProjectsPages' || 0,
        pastProjectsPages: 'AllProjectsStore/pastProjectsPages' || 0,
      }),
      ...mapGetters({
        newSubtasks: 'AllSubtasksStore/newSubtasks' || [],
        proactiveSubtasks: 'AllSubtasksStore/proactiveSubtasks' || [],
        rejectedSubtasks: 'AllSubtasksStore/rejectedSubtasks' || [],
        activeSubtasks: 'AllSubtasksStore/activeSubtasks' || [],
        finishedSubtasks: 'AllSubtasksStore/finishedSubtasks' || [],
        cancelledSubtasks: 'AllSubtasksStore/cancelledSubtasks' || [],
        requestedReviewSubtasks: 'AllSubtasksStore/requestedReviewSubtasks' || [],
        changesRequiredSubtasks: 'AllSubtasksStore/changesRequiredSubtasks' || [],
        newSubtasksPages: 'AllSubtasksStore/newSubtasksPages' || 0,
        proactiveSubtasksPages: 'AllSubtasksStore/proactiveSubtasksPages' || 0,
        rejectedSubtasksPages: 'AllSubtasksStore/rejectedSubtasksPages' || 0,
        cancelledSubtasksPages: 'AllSubtasksStore/cancelledSubtasksPages' || 0,
        activeSubtasksPages: 'AllSubtasksStore/activeSubtasksPages' || 0,
        requestedReviewSubtasksPages: 'AllSubtasksStore/requestedReviewSubtasksPages' || 0,
        changesRequiredSubtasksPages: 'AllSubtasksStore/changesRequiredSubtasksPages' || 0,
        finishedSubtasksPages: 'AllSubtasksStore/finishedSubtasksPages' || 0,
      }),
      myProjectsText() { return this.$pgettext('translation', 'My Projects'); },
      myTranslationProjects() { return this.$pgettext('translation', 'My Translation Projects'); },
      availableAssignments() { return this.$pgettext('translation', 'Available Assignments'); },
      acceptedAssignments() { return this.$pgettext('translation', 'Accepted Assignments'); },
      filter() { return this.$gettext('Filter'); },
      orderReferenceText() { return this.$gettext('Order reference'); },
      searchText() { return this.$gettext('Search'); },
      orderTranslation() { return this.$pgettext('translation', 'Order Translation'); },
      isPastView() { return this.currentView === 'past'; },
      isActiveView() { return this.currentView === 'active'; },
      isProactiveView() { return this.availableListView === 'proactive'; },
      isNewView() { return this.availableListView === 'new'; },
      isRejectedView() { return this.availableListView === 'rejected'; },
      isCancelledView() { return this.availableListView === 'cancelled'; },
      isAcceptedActiveView() { return this.acceptedListView === 'active'; },
      isChangesRequiredView() { return this.acceptedListView === 'changesRequired'; },
      isReviewRequestedView() { return this.acceptedListView === 'reviewRequested'; },
      isFinishedView() { return this.acceptedListView === 'finished'; },
      projects() { return this.currentView === 'active' ? this.activeProjects : this.pastProjects; },
      showColleaguesOrders() { return this.profileData?.enterprise?.member?.showColleaguesOrders || false; },
      availableSubtasks() {
        switch (this.availableListView) {
          case 'proactive':
            return this.proactiveSubtasks;
          case 'new':
            return this.newSubtasks;
          case 'rejected':
            return this.rejectedSubtasks;
          case 'cancelled':
            return this.cancelledSubtasks;
        }
      },
      acceptedSubtasks() {
        switch (this.acceptedListView) {
          case 'active':
            return this.activeSubtasks;
          case 'reviewRequested':
            return this.requestedReviewSubtasks;
          case 'changesRequired':
            return this.changesRequiredSubtasks;
          case 'finished':
            return this.finishedSubtasks;
        }
      },
      availableSubtasksPages() {
        switch (this.availableListView) {
          case 'proactive':
            return this.proactiveSubtasksPages;
          case 'new':
            return this.newSubtasksPages;
          case 'rejected':
            return this.rejectedSubtasksPages;
          case 'cancelled':
            return this.cancelledSubtasksPages;
        }
      },
      acceptedSubtasksPages() {
        switch (this.acceptedListView) {
          case 'active':
            return this.activeSubtasksPages;
          case 'reviewRequested':
            return this.requestedReviewSubtasksPages;
          case 'changesRequired':
            return this.changesRequiredSubtasksPages;
          case 'finished':
            return this.finishedSubtasksPages;
        }
      },
      buyersTabs() {
        return [
          {
            tab: 'active',
            isActive: this.isActiveView,
            title: this.$pgettext('translation', 'Active'),
            onClick: this.setCurrentView
          },
          {
            tab: 'past',
            isActive: this.isPastView,
            title: this.$pgettext('translation', 'Earlier'),
            onClick: this.setCurrentView
          }
        ];
      },
      availableSubtasksTabs() {
        return [
          {
            tab: 'proactive',
            isActive: this.isProactiveView,
            title: this.$pgettext('translation', 'Proactive'),
            onClick: this.setAvailableView,
          },
          {
            tab: 'new',
            isActive: this.isNewView,
            title: this.$pgettext('translation', 'New'),
            onClick: this.setAvailableView
          },
          {
            tab: 'rejected',
            isActive: this.isRejectedView,
            title: this.$pgettext('translation', 'Rejected'),
            onClick: this.setAvailableView
          },
          {
            tab: 'cancelled',
            isActive: this.isCancelledView,
            title: this.$pgettext('translation', 'Cancelled'),
            onClick: this.setAvailableView
          }
        ];
      },
      filteredAvailableSubtasksTabs() {
        if (this.isProactiveUser) {
          return this.availableSubtasksTabs;
        } else {
          return this.availableSubtasksTabs.filter((tab) => tab.tab != 'proactive');
        }
      },
      acceptedSubtasksTabs() {
        return [
          {
            tab: 'active',
            isActive: this.isAcceptedActiveView,
            title: this.$pgettext('translation', 'Active'),
            onClick: this.setAcceptedView
          },
          {
            tab: 'reviewRequested',
            isActive: this.isReviewRequestedView,
            title: this.$pgettext('translation', 'Review requested'),
            onClick: this.setAcceptedView
          },
          {
            tab: 'changesRequired',
            isActive: this.isChangesRequiredView,
            title: this.$pgettext('translation', 'Changes required'),
            onClick: this.setAcceptedView
          },
          {
            tab: 'finished',
            isActive: this.isFinishedView,
            title: this.$pgettext('translation', 'Finished'),
            onClick: this.setAcceptedView
          }
        ];
      },
      totalProjectsPages() {
        return this.currentView === 'active' ? this.activeProjectsPages : this.pastProjectsPages;
      },
      projectsPageExists() {
        return this.currentProjectsPage <= this.totalProjectsPages && this.currentProjectsPage > 0;
      },
      subtasksPageExists() {
        return (this.currentAvailableSubtasksPage <= this.availableSubtasksPages && this.currentAvailableSubtasksPage > 0)
          && (this.currentAcceptedSubtasksPage <= this.acceptedSubtasksPages && this.currentAcceptedSubtasksPage > 0);
      },
      availableScope() {
        const statuses = {
          new: {
            status: 'active'
          },
          rejected: {
            status: 'rejected'
          },
          cancelled: {
            status: 'cancelled'
          },
        };
        return statuses[this.availableListView].status;
      },
      acceptedScope() {
        const statuses = {
          active: {
            status: 'active'
          },
          finished: {
            status: 'finished'
          },
          reviewRequested: {
            status: 'review_requested'
          },
          changesRequired: {
            status: 'changes_required'
          },
        };
        return statuses[this.acceptedListView].status;
      }
    },
    watch: {
      enterpriseMemberJobsScope() {
        this.removeAllProjectsData();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    methods: {
      ...mapMutations('AllProjectsStore', [
        'removeAllProjectsData'
      ]),
      ...mapMutations('AllSubtasksStore', [
        'removeAllSubtasksData'
      ]),
      goToCreateTranslation() {
        this.$router.push({name: 'BuyerPostTranslationProject'});
      },
      searchProject(term) {
        if (this.isActiveView) {
          this.$store.commit('AllProjectsStore/startProjectProgress');
          if (this.currentHeaderTab === 'my_projects') {
            this.$store.dispatch('AllProjectsStore/fetchActiveProjects', {term});
          } else {
            this.$store.dispatch('AllProjectsStore/fetchActiveProjects', {term, scope: this.currentHeaderTab});
          }
        }
        if (this.isPastView) {
          this.$store.commit('AllProjectsStore/startProjectProgress');
          if (this.currentHeaderTab === 'my_projects') {
            this.$store.dispatch('AllProjectsStore/fetchPastProjects', {term});
          } else {
            this.$store.dispatch('AllProjectsStore/fetchPastProjects', {term, scope: this.currentHeaderTab});
          }
        }
      },
      searchAvailableSubtasks(term) {
        const params = {
          status: this.availableScope,
          term
        };
        this.$store.commit('AllSubtasksStore/startAvailableSubtaskProgress');
        this.$store.dispatch('AllSubtasksStore/fetchAvailableSubtasks', params).then(() => {
          this.$store.commit('AllSubtasksStore/stopAvailableSubtaskProgress');
        }).catch(() => {
          this.$store.commit('AllSubtasksStore/stopAvailableSubtaskProgress');
        });
      },
      searchAcceptedSubtasks(term) {
        const params = {
          status: this.acceptedScope,
          term
        };
        this.$store.commit('AllSubtasksStore/startAcceptedSubtaskProgress');
        this.$store.dispatch('AllSubtasksStore/fetchAcceptedSubtasks', params).then(() => {
          this.$store.commit('AllSubtasksStore/stopAcceptedSubtaskProgress');
        }).catch(() => {
          this.$store.commit('AllSubtasksStore/stopAcceptedSubtaskProgress');
        });
      },
      customerCardColor(status) {
        const statuses = ['quote_requested', 'quote_accepted', 'quote_rejected', 'quote_sent'];
        if (statuses.includes(status)) {
          return {
            textColor: '#003855',
            bgColor: '#99afbb'
          };
        } else if (status === 'in_progress') {
          return {
            textColor: '#008489',
            bgColor: '#b2dadc'
          };
        } else if (status === 'finished' || status === 'completed') {
          return {
            textColor: '#2b255c',
            bgColor: '#d5d3de'
          };
        } else if (status === 'cancelled') {
          return {
            textColor: '#e52322',
            bgColor: '#f5a7aa'
          };
        } else {
          return {
            textColor: '#003855',
            bgColor: '#99afbb'
          };
        }
      },
      sellersCardColor(status) {
        const statuses = ['published', 'invited', 'applied', 'proactive_access'];
        if (statuses.includes(status)) {
          return {
            textColor: '#003855',
            bgColor: '#99afbb',
            whiteCard: true
          };
        } else if (status === 'in_progress' || status === 'accepted') {
          return {
            textColor: '#008489',
            bgColor: '#b2dadc',
            whiteCard: true
          };
        } else if (status === 'draft') {
          return {
            textColor: '#000',
            bgColor: '#b2b2b2',
            whiteCard: true
          };
        } else if (status === 'review_requested' || status === 'changes_required') {
          return {
            textColor: '#b68e56',
            bgColor: '#e9ddcc',
            whiteCard: true
          };
        } else if (status === 'finished') {
          return {
            textColor: '#2b255c',
            bgColor: '#d5d3de',
            whiteCard: true
          };
        } else if (status === 'cancelled' || status === 'rejected') {
          return {
            textColor: '#e52322',
            bgColor: '#f5a7aa',
            whiteCard: true
          };
        }
      },
      setCurrentView(view) {
        this.currentView = view;
        this.currentProjectsPage = 1;
        if (this.showColleaguesOrders) {
          this.fetchProjects();
        } else {
          this.$router.push(this.$makeRoute({path: 'all', query: {page: 1, view: view}}));
        }
      },
      setCurrentHeaderTab(headerTab) {
        this.currentHeaderTab = headerTab;
        this.setCurrentView('active');
        this.currentProjectsPage = 1;
        this.fetchProjects();
      },
      setAvailableView(view) {
        this.availableListView = view;
        this.currentAvailableSubtasksPage = 1;
        this.fetchSubtasks();
      },
      setAcceptedView(view) {
        this.acceptedListView = view;
        this.currentAcceptedSubtasksPage = 1;
        this.fetchSubtasks();
      },
      fetchProjects() {
        const params = {
          view: this.currentView,
          page: this.projectsPageExists ? this.currentProjectsPage : 1
        };
        const page = params.page;
        if (this.currentHeaderTab === 'my_projects') {
          this.$router.push(this.$makeRoute({path: 'all', query: {page: page, view: this.currentView}}));
        } else {
          this.$router.push(this.$makeRoute({path: 'all', query: {page: page, view: this.currentView, scope: this.currentHeaderTab}}));
        }
      },
      fetchSubtasks() {
        const availableStatus = this.availableListView;
        const availablePage = this.subtasksPageExists ? this.currentAvailableSubtasksPage : 1;

        const acceptedStatus = this.acceptedListView;
        const acceptedPage = this.subtasksPageExists ? this.currentAcceptedSubtasksPage : 1;

        this.$router.push(this.$makeRoute({path: 'all', query: {availableStatus, availablePage, acceptedStatus, acceptedPage}}));
      },
      toPrevPage(paginationObj) {
        const {field} = paginationObj;
        switch (field) {
          case 'projects':
            this.currentProjectsPage -= 1;
            this.fetchProjects();
            break;
          case 'availableSubtasks':
            this.currentAvailableSubtasksPage -= 1;
            this.fetchSubtasks();
            break;
          case 'acceptedSubtasks':
            this.currentAcceptedSubtasksPage -= 1;
            this.fetchSubtasks();
            break;
        }
      },
      toNextPage(paginationObj) {
        const {field} = paginationObj;
        switch (field) {
          case 'projects':
            this.currentProjectsPage += 1;
            this.fetchProjects();
            break;
          case 'availableSubtasks':
            this.currentAvailableSubtasksPage += 1;
            this.fetchSubtasks();
            break;
          case 'acceptedSubtasks':
            this.currentAcceptedSubtasksPage += 1;
            this.fetchSubtasks();
            break;
        }
      },
      toNewPage(paginationObj) {
        const {field, page} = paginationObj;
        switch (field) {
          case 'projects':
            this.currentProjectsPage = +page;
            this.fetchProjects();
            break;
          case 'availableSubtasks':
            this.currentAvailableSubtasksPage = +page;
            this.fetchSubtasks();
            break;
          case 'acceptedSubtasks':
            this.currentAcceptedSubtasksPage = +page;
            this.fetchSubtasks();
            break;
        }
      },
      handleNotExistingPage() {
        if (this.userIsInterpreter) {
          this.currentAvailableSubtasksPage = 1;
          this.currentAcceptedSubtasksPage = 1;
          this.fetchSubtasks();
        } else {
          this.currentProjectsPage = 1;
          this.fetchProjects();
        }
      },
    },
    mounted() {
      if (!this.projectsPageExists || !this.subtasksPageExists) {
        this.handleNotExistingPage();
      }
      if (!this.$cookieManager.getCookie('lastUsedType') || this.$cookieManager.getCookie('lastUsedType') !== 'translation') {
        this.$cookieManager.setCookie('lastUsedType', 'translation');
      }
    },
    beforeDestroy() {
      this.removeAllProjectsData();
      this.removeAllSubtasksData();
    },
    beforeRouteUpdate(to, from, next) {
      this.$store.commit('AllProjectsStore/setActiveProjectsRequested', false);
      this.$store.commit('AllProjectsStore/setPastProjectsRequested', false);
      next();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('s_DashboardStore/clearFeedbackJobs');
      next();
    },
    beforeRouteEnter(to, from, next) {
      next();
      // fallback url query when use we have no or some queries
      if (to.name === 'BuyerAllProjects') {
        if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
          next({path: to.path, query: {view: 'active', items: 10, page: 1}});
        } else if (to.query.view == 'active' || to.query.view == 'past') {
          next({query: {view: 'active', items: 10, page: 1}});
        }
      } else {
        if (to.query.availableStatus == null && to.query.acceptedStatus == null) {
          next({path: to.path, query: {availableStatus: 'new', availablePage: 1, acceptedStatus: 'active', acceptedPage: 1}});
        } else if (to.query.availableStatus !== null && to.query.acceptedStatus == null) {
          next({path: to.path, query: {availableStatus: to.query.availableStatus, availablePage: 1, acceptedStatus: 'active', acceptedPage: 1}});
        } else if (to.query.availableStatus == null && to.query.acceptedStatus !== null) {
          next({path: to.path, query: {availableStatus: 'new', availablePage: 1, acceptedStatus: to.query.acceptedStatus, acceptedPage: 1}});
        } else if (to.query.availableStatus !== null && to.query.acceptedStatus !== null) {
          next();
        }
      }
    }
  };
</script>

<style scoped>
.projects-page {
  background-color: #fff;
}

.seller-projects__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: 768px) {
  .seller-projects {
    position: relative;
    padding-bottom: 40px;
  }

  .seller-projects::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 260px;
    background-color: #f4f5f7;
  }

  .seller-projects__container {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-gap: 20px;
    width: 100%;
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .projects-page {
    min-height: calc(100vh - 120px);
  }

  .seller-projects__container {
    max-width: 90%;
    margin: 0 auto;
  }
}
</style>
